import axios from '../axios/index'

// 实名认证
export const authentication = (param) => {
    return axios.post('/auth/authentication', param)
}
// 获取认证
export const getAuthentication = (param) => {
    return axios.post('/auth/getAuthentication', param)
}

// 获取认证
export const setAuthenticate = (param) => {
    return axios.post('/auth/authenticate', param)
}

// 获取二维码结果
export const getAuthenticateResult = (param) => {
    return axios.get('/auth/getAuthenticateResult', param)
}

// 三要素
export const phoneAuthentication = (param) => {
    return axios.post('/auth/phoneAuthentication', param)
}

// 编辑实名认证
export const updateAuth = (param) => {
    return axios.post('/auth/update', param)
}
