import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/assets/style/styles.scss'
import 'element-plus/dist/index.css'
// import VueQrcode from 'vue-qrcode'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// app.component('VueQrcode', VueQrcode)
app.use(router)
app.use(store)

app.use(ElementPlus, {
    locale: zhCn
})



app.mount('#app')
