import axios from '../axios/index'

// 省市编码
export const getProvinceCode = (param) => {
    return axios.get('/common/provinceCityCode', param)
}

// 国家区域代码
export const getCountryCode = (param) => {
    return axios.get('/common/countryCode', param)
}

// 电商平台
export const getPlatformCode = (param) => {
    return axios.get('/common/platformCode', {params: param})
}

// 中国大陆代发银行列表
export const getAllBanks = (param) => {
    return axios.get('/bank/allBanks', param)
}

// 中国大陆代发银行列表
export const ocr = (param) => {
    return axios.post('/ocr/idCard', param)
}

// 获取货币对实时汇率
export const getExchangeRateByCur = (param) => {
    return axios.get('/huifu/getExchangeRateByCur', {params: param})
}

// 获取汇率
export const businessLicense = (param) => {
    return axios.post('/ocr/businessLicense', param)
}

// 发送自己的手机验证码
export const sendVerifyCodeByUser = (param) => {
    return axios.post('/sendVerifyCodeByUser', param)
}

// 发送自己的邮箱验证码
export const sendEmailVerifyCodeByUser = (param) => {
    return axios.post('/sendEmailVerifyCodeByUser', param)
}

// 发送邮箱验证码
export const sendEmailVerifyCode = (param) => {
    return axios.post('/sendEmailVerifyCode', param)
}
// 发送邮箱激活链接
export const sendActiveEmail = (param) => {
    return axios.post('users/sendActiveEmail', param)
}
// 下载
export const downloadImg = (params) => {
    return axios.get(`download/${params}`)
}
