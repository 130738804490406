import axios from '../axios/index'

// 修改登录密码
export const updatePassword = (param) => {
    return axios.post('/users/updatePassword', param)
}

// 修改交易密码
export const updatePayPassword = (param) => {
    return axios.post('/users/updateTraderPassword', param)
}

// 修改手机号
export const updatePhone = (param) => {
    return axios.post('/users/updatePhone', param)
}

// 修改邮箱
export const updateEmail = (param) => {
    return axios.post('/users/updateEmail', param)
}

// 修改邮箱
export const userInfo = (param) => {
    return axios.get('/users/info', param)
}
