<template>
    <router-view></router-view>
</template>

<script setup>
import { RouterView } from 'vue-router'

const debounce = (fn, delay) => {
    let timer = null;

    return function () {
        let context = this;

        let args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};

</script>

<style >
html{
    background: #f4f5f9;
    height: 100%;
}
#app {
  height: 100%;
  font-family: Microsoft YaHei,Source Han Sans SC,HanHei SC,PingFang SC,Helvetica Neue,Helvetica,Hiragino Sans GB,\\5FAE\8F6F\96C5\9ED1,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    color: #666666;
    font-size: 14px;
}

body::-webkit-scrollbar-track, div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
    border-radius: 0;
    background: rgba(0,0,0,.1);
}

body::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
    background: #aaa;
}
body::-webkit-scrollbar, div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
</style>
