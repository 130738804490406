// 对axios进行二次封装 目的使用请求与响应拦截器
import axios from 'axios'
// 引入element-plus中的消息提示的组件ElMessage
import { ElMessage } from 'element-plus'
import router from '../router'
import store from '../store'


// 第一步：利用axios对象的create方法创建axios实例（配置其他的配置：基础路径、超时的时间等）
const request = axios.create({
    // 利用axios自定义配置
    // baseURL: process.env.VUE_APP_BASE_API,//ggbone,
     baseURL: '/api',//ggbone,

})
// 第二步：request实例添加请求与响应拦截器-interceptors拦截器
request.interceptors.request.use((config) => {

    // 如果有 token 则将 token 放到请求头中
    config.headers.token = localStorage.getItem('token')
    config.headers.Authorization = localStorage.getItem('token')
    // config配置对象中的headers属性请求头：经常用于给服务器端携带公共参数

    return config // 返回配置对象
})
// 第三步：响应拦截器
request.interceptors.response.use(
    (response) => {
        ElMessage.closeAll();
        console.log(response, 'response');

        switch (response.data.code) {
            case 0 : {
                return response.data // 简化数据 只拿响应对象中的data数据，不用拿到所有数据组成的对象
            }
            case -1 : {
                setTimeout(()=> {
                    ElMessage({type: 'error', message: '系统错误'})
                }, 200)
                return response.data // 简化数据 只拿响应对象中的data数据，不用拿到所有数据组成的对象
            }
            case -4 : {
                setTimeout(()=> {
                    ElMessage({type: 'error', message: '手机号，姓名，身份证号码不一致'})
                }, 200)
                return response.data // 简化数据 只拿响应对象中的data数据，不用拿到所有数据组成的对象
            }
            case 600 : {
                setTimeout(()=> {
                    ElMessage({type: 'error', message: response.data.msg})
                }, 200)

                localStorage.clear()
                store.commit('_setUserMsg', {})
                store.commit('_setToken', '')
                router.push({path: '/signIn'})
                return response.data // 简化数据 只拿响应对象中的data数据，不用拿到所有数据组成的对象
            }
            default: {
                // ElMessage({type: 'error', message: response.data.msg})

                if (response.config.responseType === 'blob' && response.data) {
                    ElMessage({type: 'success', message: '导出成功'})
                } else  {
                    setTimeout(()=> {
                        ElMessage({type: 'error', message: response.data.msg})
                    }, 200)
                }
                return response.data // 简化数据 只拿响应对象中的data数据，不用拿到所有数据组成的对象
            }
        }
    },
    (error) => {
        ElMessage.closeAll();
        // 处理http网络错误
        // 定义一个变量：存储网络错误信息
        // http状态码
        // 提示错误信
        ElMessage({type: 'error', message: '系统错误'})
        // 返回失败的promise 去终结Promise
        return Promise.reject(error)
    },
)
export default request
