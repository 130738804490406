import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
    easing: 'ease', // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3,// 初始化时的最小百分比,
})
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: '',
            component: () => import('@/views/contexts/index.vue'),
            meta: {
                index: 1
            },
            children: [
                {
                    path: '',
                    name: '',
                    component: () => import('@/views/contexts/children/account.vue'),
                },
                {
                    path: 'platform',
                    name: 'platform',
                    component: () => import('@/views/contexts/children/platform/platform.vue'),
                },
                {
                    path: 'platformDetails/:id',
                    name: 'platformDetails',
                    component: () => import('@/views/contexts/children/platform/platformDetails.vue'),
                },
                {
                    path: 'receiptmanage',
                    name: 'receiptmanage',
                    component: () => import('@/views/contexts/children/receiptmanage/index.vue'),
                },
                // 明细查询
                {
                    path: 'finance',
                    name: 'finance',
                    component: () => import('@/views/contexts/children/finance/index.vue'),
                },

                // 付款服务
                {
                    path: 'supplierManage',
                    name: 'supplierManage',
                    component: () => import('@/views/contexts/children/supplierManage/index.vue'),
                },
                // 付款服务
                {
                    path: 'addSupplier',
                    name: 'addSupplier',
                    component: () => import('@/views/contexts/children/supplierManage/addSupplier.vue'),
                },
                // 付款服务 境内付款
                {
                    path: 'supplierPayDetail',
                    name: 'supplierPayDetail',
					
                    component: () => import('@/views/contexts/children/supplierManage/supplierPayDetail.vue'),
                },
                // 付款服务 付款
                {
                    path: 'abroadBcPayment',
                    name: 'abroadBcPayment',
                    component: () => import('@/views/contexts/children/supplierManage/abroadBcPayment.vue'),
                },
                // 付款服务 付款
                {
                    path: 'payTax',
                    name: 'payTax',
                    component: () => import('@/views/contexts/children/supplierManage/vat/payTax.vue'),
                },
				// 编辑收款人
				{
				    path: 'editSupplier/:id',
				    name: 'editSupplier',
				    component: () => import('@/views/contexts/children/supplierManage/editSupplier.vue'),
				},
                // 汇兑服务
                {
                    path: 'standardFx',
                    name: 'standardFx',
                    component: () => import('@/views/contexts/children/standardFx/index.vue'),
                },
                /*立即提现*/
                {
                    path: 'cash',
                    name: 'cash',
                    component: () => import('@/views/contexts/children/cash/index.vue'),
                },
                /*申请收款账号*/
                {
                    path: 'addshop',
                    name: 'addshop',
                    meta: {
                        noCache: false
                    },
                    component: () => import('@/views/contexts/children/addshop/index.vue'),
                },
                // 用户中心
                {
                    path: 'user',
                    name: 'user',
                    component: () => import('@/views/contexts/children/user/index.vue'),
                },

                {
                    path: 'cashaccount',
                    name: 'cashaccount',
                    component: () => import('@/views/contexts/children/user/cashaccount/index.vue'),
                },
                {
                    path: 'modifypassword',
                    name: 'modifypassword',
                    component: () => import('@/views/contexts/children/user/modifypassword.vue'),
                },
                {
                    path: 'payPassword',
                    name: 'payPassword',
                    component: () => import('@/views/contexts/children/user/payPassword.vue'),
                },
                {
                    path: 'changePhone',
                    name: 'changePhone',
                    component: () => import('@/views/contexts/children/user/changePhone.vue'),
                },
                {
                    path: 'editMail',
                    name: 'editMail',
                    component: () => import('@/views/contexts/children/user/editMail.vue'),
                },
                {
                    path: 'order',
                    name: 'order',
                    component: () => import('@/views/contexts/children/user/order/index.vue'),
                },
                {
                    path: 'order/uploadOrder',
                    name: 'uploadOrder',
                    component: () => import('@/views/contexts/children/user/order/uploadOrder.vue'),
                },

                // 实名认证
                {
                    path: 'authentication',
                    name: 'authentication',
                    component: () => import('@/views/contexts/children/user/authentication/authentication.vue'),
                },
                // 实名认证个人
                {
                    path: 'authentication/user',
                    name: 'authentication/user',
                    component: () => import('@/views/contexts/children/user/authentication/user.vue'),
                },
                // 实名认证公司
                {
                    path: 'authentication/company',
                    name: 'authentication/company',
                    component: () => import('@/views/contexts/children/user/authentication/company.vue'),
                },
            ]
        },
        {
            path: '/signIn',
            name: 'signIn',
            component: () => import('@/views/login/signIn.vue'),
            meta: {
                title: '登录'
            }
        },
        {
            path: '/regist',
            name: 'regist',
            component: () => import('@/views/login/regist.vue'),
            meta: {
                title: '注册'
            }
        },
        {
            path: '/activeEmail',
            name: 'activeEmail',
            component: () => import('@/views/login/activeEmail.vue'),
            meta: {
                title: '验证邮箱'
            }
        },
        {
            path: '/forgot',
            name: 'forgot',
            component: () => import('@/views/login/forgot.vue'),
            meta: {
                title: '找回密码'
            }
        },
        {
            path: '/h5',
            name: 'h5',
            component: () => import('@/views/h5/index.vue'),
            meta: {
                title: '人脸识别成功'
            }
        },
        {
            path: '/yinshi',
            name: 'yinshi',
            component: () => import('@/views/agreement/yinshi.vue'),
            meta: {
                title: '隐私条款'
            }
        },
        {
            path: '/fuwu',
            name: 'fuwu',
            component: () => import('@/views/agreement/fuwu.vue'),
            meta: {
                title: '服务协议'
            }
        },
        {
            path: '/suoshuquan',
            name: 'suoshuquan',
            component: () => import('@/views/agreement/suoshuquan.vue'),
            meta: {
                title: '店铺所属权确认'
            }
        }
    ]
})

router.beforeEach((to, from, next) => {
    //这这里开启加载条
    NProgress.start()
    next()
})
router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})
export default router
