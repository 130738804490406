import Vuex from 'vuex'
import {getProvinceCode, getCountryCode, getPlatformCode} from '@/api/common'
import {getAuthentication} from "@/api/authentication";
import {userInfo} from "@/api/user";
const getStore = (key) => {return localStorage.getItem(key)}
const setStore = (key, value) => {return localStorage.setItem(key, value)}
const useMsg = getStore('useMsg') ? JSON.parse(getStore('useMsg')) : ''
export default new Vuex.Store({
    state: {
        token: getStore('token'),
        useMsg: useMsg,
        companyMsg: useMsg && getStore(useMsg.id) ? JSON.parse(getStore(useMsg.id)) : {},
        addressList: [],
        countryList: [],
        platformList: [],
        currencyList: [
            {
                value: 'CNY',
                label: '人民币'
            },
            {
                value: 'USD',
                label: '美元'
            },
            {
                value: 'EUR',
                label: '欧元'
            },
            {
                value: 'GBP',
                label: '英镑'
            },
            {
                value: 'JPY',
                label: '日元'
            },
            {
                value: 'AUD',
                label: '澳元'
            },
            {
                value: 'SGD',
                label: '新加坡元'
            },
            {
                value: 'CAD',
                label: '加元'
            },
            {
                value: 'HKD',
                label: '港币'
            }
        ],
        authentication: {}
    },
    mutations: {
        _setUserMsg (state, aug) {
            setStore('useMsg', JSON.stringify(aug))
            state.useMsg = aug
        },
        _setToken (state, aug) {
            state.token = aug
            setStore('token', aug)
        },
        _setPlatformList (state, aug) {
            state.platformList = aug
        },
        _setAuthentication (state, aug) {
            state.authentication = aug
        },
        _setCompanyMsg (state, aug) {
            console.log(state.companyMsg[aug.key], aug)
			let companyMsg = JSON.parse(JSON.stringify(state.companyMsg)) 
            if (companyMsg[aug.key]) {
                companyMsg[aug.key][aug.type] = aug.msg
            } else {
                companyMsg[aug.key] = [aug.msg]
            }
           let id =  state.useMsg.id
            
			state.companyMsg = companyMsg
			console.log(state.companyMsg, 'state.companyMsg');
            setStore(id, JSON.stringify(state.companyMsg))
        },
        _setAddressList (state, aug) {
            state.addressList = aug
        },
        _setCountryListList (state, aug) {
            state.countryList = aug
        }
    },
    actions: {
        getUserInfo (ctx) {
            userInfo().then(
                res =>{
                    let data = {}
                    if (res.data) {
                        data = {
                            ...res.data,
                            id: res.data.id.toString()
                        }
                    }

                    ctx.commit('_setUserMsg', data)
                }
            )
        },
        getAddressList (ctx) {
            getProvinceCode().then(
                res =>{
                    ctx.commit('_setAddressList', res.data)
                    console.log('地址执行完毕')
                }
            )
        },
        getPlatform (ctx) {
            getPlatformCode({type: 'selfBuilt'}).then(
                res =>{
                    ctx.commit('_setPlatformList', res.data)
                }
            )
        },
        getCountryList (ctx) {
            getCountryCode().then(
                res =>{
                    ctx.commit('_setCountryListList', res.data.map(m=> {return {value: m.code, label: m.nameEn + '-' + m.nameCn}}))
                }
            )
        },
        getAuthentication (ctx) {
            return new Promise((resolve, reject)=> {
                getAuthentication().then(
                    res =>{
                        console.log(res, 'getAuthentication')
                        resolve(res)
                        if (res.code === 0) {
                            ctx.commit('_setAuthentication', res.data)
                        }
                    }
                ).catch((err)=>{
                    reject(err)
                })
            })

        }
    },
    getters: {
        getCompanyMsg(state) {
            console.log(getStore(state.useMsg.id + ''), 'state.useMsgstate.useMsg')
            return state.companyMsg
        }
    }
})
